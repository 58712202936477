<template>
    <div>
        <div id="mySidenavMenu" v-show="menuAberto" :class="{ 'sidenav-menu gray-scroll': true, 'menu-aberto': menuAberto }">
            <div class="shadow container-menu pb-3">
                <div class="container-menu-title">
                    <div class="menu-title">
                        Linx Microvix
                        <div class="menu-sub-title mt-1">WMS</div>
                    </div>
                </div>
                <div id="lmxta-menu-btn-fechar" class="cursor-pointer closebtn" @click="fecharMenuLateral">
                    <i class="fas fa-times fa-lg"></i>
                </div>
                <div class="row mt-2">
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                        <div class="m-0 fz-80">{{ usuarioLogado.NomeCompleto }}</div>
                        <div class="m-0 h5 fonte-info-empresa">Cód. {{ usuarioLogado.Id }}</div>
                        <div class="m-0 h6 fonte-info-empresa">Portal {{ empresaSelecionada.Portal }} - ({{ empresaSelecionada.Id }}) {{ empresaSelecionada.Nome }}</div>
                    </div>
                </div>
                <div class="row pt-3 px-2" v-if="permiteAlterarEmpresa">
                    <div class="col-12 d-flex flex-column justify-content-center align-items-center">
                        <button class="btn btn-secondary btn-block fz-70 m-0 p-1" id="lmxta-alterar-empresa" @click="alterarEmpresa()">
                            <span class="pr-2"> <i class="fas fa-sync"></i> </span>Alterar Empresa
                        </button>
                    </div>
                </div>
            </div>
            <hr class="mt-0 mb-2" />
            <div class="row pb-3">
                <div class="col-12 d-flex flex-column h-100 align-items-start ml-3 text-uppercase">
                    <!-- Home -->
                    <item-menu :descricao="'Home'" :rota="'home'" :icone="'fas fa-home fa-lg'"> </item-menu>
                    <!-- Pesquisa de Produtos -->
                    <item-menu :descricao="'Produtos'" :rota="'produto-pesquisa'" :icone="'fas fa-search-location fa-lg'"> </item-menu>
                    <!-- Painel de Movimentações -->
                    <item-menu :descricao="'Painel de Movimentações'" :rota="'movimentacoes'" :icone="'fas fa-random fa-lg'"> </item-menu>
                    <!-- Balanço -->
                    <item-menu :descricao="'Balanço Fácil'" :rota="'filtros-balancos'" :icone="'fas fa-file-alt fa-lg'"> </item-menu>
                    <!-- Menu de ajuda -->
                    <item-menu :descricao="'Ajuda'" :rota="'ajuda'" :icone="'fas fa-question fa-lg'"> </item-menu>
                    <!-- Botão Sair -->
                    <item-menu :descricao="'Sair'" :rota="'logout'" :icone="'fas fa-power-off fa-lg'" v-if="ehAutenticacaoWMS"> </item-menu>
                </div>
            </div>
        </div>
        <div id="blockout" v-show="menuAberto" @click="fecharMenuLateral"></div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import itemMenu from './item-menu.vue';

export default {
    components: { itemMenu },
    name: 'Menu',
    computed: {
        ...mapGetters({
            menuAberto: 'uiState/menuAberto',
            ehAutenticacaoWMS: 'auth/ehAutenticacaoWMS',
            usuarioLogado: 'auth/usuarioLogado',
            empresaSelecionada: 'auth/empresaSelecionada',
            portaisUsuario: 'auth/portaisUsuario',
            empresasUsuario: 'auth/empresasUsuario'
        }),
        permiteAlterarEmpresa() {
            return this.ehAutenticacaoWMS && this.portaisUsuario && this.portaisUsuario[0].Empresas.length;
        }
    },

    methods: {
        alterarEmpresa: function() {
            this.fecharMenuLateral();
            this.$router.push({ name: 'login' });
        },

        fecharMenuLateral() {
            this.$store.dispatch('uiState/toggleMenu');
        }
    }
};
</script>

<style scoped>
#blockout {
    height: 100vh;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
    background: rgba(0, 0, 0, 0.3);
}
.container-menu {
    background-color: #470279;
}
.container-menu-title {
    height: 3.5rem;
}

.menu-title {
    position: relative;
    text-align: center;
    font-family: Dosis, sans-serif;
    font-size: 1.5rem;
    color: #ffb200;
}
.menu-sub-title {
    text-align: center;
    font-family: Dosis, sans-serif;
    font-weight: bold;
    font-size: 1.4rem;
    color: #fff;
    position: absolute;
    top: 55%;
    text-align: center;
    width: 100%;
}

.fonte-info-empresa {
    font-size: 0.6rem;
    color: #b1b1b1;
}

.bloqueio-tela {
    display: none !important;
}

.menu-aberto {
    left: 0 !important;
}

.sidenav,
.sidenav-menu {
    overflow-x: hidden;
    color: #fff;
    height: 100vh;
    width: 51px;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    padding-top: 15px;
    padding-bottom: 20px;
    background-color: #2c004b;
}

.sidenav-menu {
    width: 250px;
    padding-top: 0;
    z-index: 1032;
    top: 0;
    left: -300px;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    background-color: #5b2e90;
}

#mySidenavMenu .card {
    background: transparent;
}

.card,
.card-header {
    border: 0;
    background: transparent;
    width: 100%;
}

.closebtn {
    z-index: 100;
}

.sidenav-menu .closebtn {
    position: absolute;
    top: 0;
    right: 15px;
    margin-top: 10px;
}

.sidenav-menu .closebtn:hover {
    text-decoration: none;
}

.sidenav-menu .title {
    font-size: 1.5rem;
    padding: 13px 45px 13px 10px;
    background: #2c004b;
}

.sidenav-menu .title img {
    vertical-align: top;
    position: relative;
    top: 2px;
}

.label-info-empresa {
    color: #b1b1b1;
    font-size: 0.875rem;
    font-weight: bolder;
}
</style>
